import { InputNumber, InputNumberProps } from 'antd'
import cx from 'classnames'
import { FC, memo } from 'react'
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form'
import { formFieldID } from '../../utils/helpers'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'

interface INumberInputField extends InputNumberProps {
	label?: string
	input: Partial<WrappedFieldInputProps>
	meta: Partial<WrappedFieldMetaProps>
	inputClassName?: string
	setZeroForNoValue?: boolean
	roundTo?: number
}

const NumberInputField: FC<INumberInputField> = ({
	label,
	placeholder,
	disabled,
	className,
	style,
	input,
	meta,
	inputClassName,
	setZeroForNoValue,
	roundTo,
	...props
}) => {
	const { name = 'field', value = undefined, onChange = undefined } = input
	const { form = 'form', error = undefined, touched = false } = meta
	const roundToAmount = roundTo || 5

	const sanitizeValue = (valueToSanitize?: number) => {
		if (valueToSanitize && valueToSanitize > roundToAmount) {
			return Math.round(valueToSanitize - (valueToSanitize % roundToAmount))
		}
		return valueToSanitize
	}

	const onAmountChange = (amount?: number) => {
		if (onChange) {
			onChange(amount)
		}
	}

	const onBlur = (e: any) => {
		setTimeout(() => onAmountChange(sanitizeValue(e.target.value)), 0)
	}

	const id = formFieldID(form, name)

	return (
		<div className={cx('form-field', className)} style={style}>
			{label && (
				<label htmlFor={id} className={'form-field-label'}>
					{label}
				</label>
			)}
			<div className={'form-field-item'}>
				<InputNumber
					id={id}
					placeholder={placeholder}
					disabled={disabled}
					value={value || (setZeroForNoValue ? 0 : undefined)}
					onChange={onChange}
					onBlur={onBlur}
					className={cx(inputClassName, { error: error && touched })}
					{...props}
				/>
				<span className={'form-field-item-suffix'}>{error && touched && <ErrorIcon />}</span>
			</div>
			{error && touched && <span className={'form-field-message'}>{error}</span>}
		</div>
	)
}

export default memo(NumberInputField)
